<template>
  <v-card :loading="loading">
    <v-toolbar flat>
      <v-toolbar-title>Report Downloads</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mr-2"
            small
            v-bind="attrs"
            v-on="on"
            @click="refresh"
            :disabled="items.length == 0"
            :loading="loading"
          >
            <v-icon class="mr-2">mdi-refresh</v-icon> Refresh Reports
          </v-btn>
        </template>
        <span>Refresh</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :server-items-length="total"
        :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
        :options.sync="options"
      >
        <template v-slot:item.createdDate="{ item }">
          {{ item.createdDate | formatDateClient("MM/DD/YYYY hh:mm a z", selectedClient) }}<br />
          ({{ item.createdDate | formatDateFromNow }})
        </template>

        <template v-slot:item.originalFilename="{ item }">
          <a v-if="item && item.upload" target="_blank" rel="noreferrer" :href="item.upload.href">{{
            item.upload.originalFilename
          }}</a>
          <span v-else>
            Not available yet
          </span>
        </template>

        <template v-slot:item.updatedDate="{ item }">
          {{ item.updatedDate | formatDateFromNow }}
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            class="ma-2"
            :color="item.status == 'COMPLETED' ? 'primary' : item.status == 'ERROR' ? 'red' : ''"
            :text-color="item.status == 'ERROR' ? 'white' : ''"
          >
            {{ item.status }}
          </v-chip>
          <span v-if="item.error">{{ item.error }}</span>
        </template>

        <template v-slot:no-data>
          No reports requested
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ReportDownloads",

  data() {
    return {
      loading: false,
      items: [],
      headers: [
        { text: "Date", value: "createdDate", sortable: true },
        { text: "Name", value: "name", sortable: true },
        { text: "File Name", value: "originalFilename" },
        { text: "Status", value: "status", align: "center", sortable: true }
      ],
      total: 0,
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ["createdDate"],
        sortDesc: [true]
      }
    };
  },
  methods: {
    fetchData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let reportForm = {};
      reportForm.participant = { id: this.selectedParticipant.id };
      reportForm.effective = true;

      return this.$api
        .post(
          "/api/reports/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          reportForm
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;

          let hasRecordInProcessing = false;
          this.items.forEach(report => {
            if (report.status == "PROCESSING") {
              hasRecordInProcessing = true;
            }
          });
          if (hasRecordInProcessing) {
            setTimeout(() => {
              this.refresh();
            }, 10000);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    refresh() {
      return this.fetchData();
    }
  },
  mounted() {
    this.fetchData();

    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Reports"
      },
      { text: "Report Downloads" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedClient"])
  }
};
</script>
