var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Report Downloads")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","disabled":_vm.items.length == 0,"loading":_vm.loading},on:{"click":_vm.refresh}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-refresh")]),_vm._v(" Refresh Reports ")],1)]}}])},[_c('span',[_vm._v("Refresh")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"footer-props":{ 'items-per-page-options': [10, 25, 50] },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateClient")(item.createdDate,"MM/DD/YYYY hh:mm a z", _vm.selectedClient))),_c('br'),_vm._v(" ("+_vm._s(_vm._f("formatDateFromNow")(item.createdDate))+") ")]}},{key:"item.originalFilename",fn:function(ref){
var item = ref.item;
return [(item && item.upload)?_c('a',{attrs:{"target":"_blank","rel":"noreferrer","href":item.upload.href}},[_vm._v(_vm._s(item.upload.originalFilename))]):_c('span',[_vm._v(" Not available yet ")])]}},{key:"item.updatedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateFromNow")(item.updatedDate))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.status == 'COMPLETED' ? 'primary' : item.status == 'ERROR' ? 'red' : '',"text-color":item.status == 'ERROR' ? 'white' : ''}},[_vm._v(" "+_vm._s(item.status)+" ")]),(item.error)?_c('span',[_vm._v(_vm._s(item.error))]):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" No reports requested ")]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }